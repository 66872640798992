<template>
  <div>
    <div class="banner">
      <warning-tip code="HISTORICAL_PRICE"></warning-tip>

      <main-top
        title="商品历史价格"
        desc="通过商品id可以获取商品历史价格趋势数据，可应用于商品销售情况分析，电商选品分析，品牌口碑挖掘和竞争对手分析等。"
        spend="HISTORICAL_PRICE"
      >
        <InputView
            :extractProductId="true"
            :CanYouClick="CanYouClick"
            placeholder-name="请输入您的宝贝链接或宝贝ID"
            @sendSearchName="getSearchName"
          ></InputView>
      </main-top>

      <bordure-row title="销售趋势" style="margin: 30px 0" shadow>
        <div class="echarts_box">
          <div class="echarts_map" ref="echarts"></div>
        </div>
      </bordure-row>

      <!-- table -->
      <bordure-row title="商品信息" style="margin: 30px 0">
        <el-table
          :data="tableData"
          max-height="500"
          size="mini"
          :header-cell-style="{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            'font-size': '14px',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }"
        >
          <el-table-column
            label="日期"
            prop="date"
            align="center"
          ></el-table-column>
          <el-table-column
            label="价格"
            prop="price"
            align="center"
          ></el-table-column>
        </el-table>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <!-- <Introduce :introduce="introduce"></Introduce> -->
    </div>

    <SyncLoading v-if="$store.state.fishFlag"></SyncLoading>
  </div>
</template>
<script>
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
import { goodsHistoryPrice } from "../../request/api"; //这里是引入请求
export default {
  data() {
    return {
      CanYouClick: true,
      expireTimeOption: {
        disabledDate(date) {
          // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() >= Date.now();
        },
      },
      formInline: {
        productId: "",
        timeDate: "",
      },
      rules: {
        productId: [
          {
            required: true,
            message: "请输入您的宝贝链接或宝贝ID",
            trigger: "blur",
          },
        ],
      },
      particularsData: [], //商品详情信息
      tableData: [],
      introduce: [
        {
          title: "竞品透视分析有哪些好处，能为淘宝卖家带来什么?",
          content: [
            "1、针对淘宝卖家如果不懂分析对手的数据，那样只有找不到头绪乱补访客和收藏，猛冲销量，是很难取得效果的。我们使用竞品透视分析功能查询出竞品的各项关键数据，如：访客数、支付买家数、交易金额、支付转化率、客单价、uv价值、收藏加购等。",
            "2、深度计算解析竞品的指标，人工操作优化自己的产品，各项指标都比竞品做得要好，淘宝就会判断你的产品比竞品更受欢迎，给你更多的访客。",
          ],
        },
      ], //介绍相关数组

      option_tjrq: [1, 2, 3], //日期

      option: {
        //这里是echarts的相关数据
        title: {
          text: "历史价格分析",
          left: "left",
        },
        tooltip: {
          trigger: "axis",
          transitionDuration: 0, //加上这个可以防止抖动
        },
        legend: {
          data: ["价格"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: [
          {
            type: "value",
          },
          {
            type: "value",
            name: "价格",
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        series: [
          {
            name: "价格",
            type: "line",
            yAxisIndex: 1,
            smooth: true,
            data: [],
          },
        ],
      },
    };
  },
  components: {
    InputView,
    Introduce,
  },
  mounted() {
    this.formInline.timeDate = this.$comm.convertDate(
      new Date().getTime(),
      "yyyy-MM-dd"
    );
  },
  methods: {
    initCharts() {
      let myChart = this.echarts.init(this.$refs.echarts);
      myChart.setOption(this.option);
    },
    // 子传父的方法
    getSearchName(value) {
      this.$store.commit("alterLoadContent", `正在搜索中,请您耐心等候...`);
      this.$store.commit("alterJumpFlag", true);
      this.CanYouClick = false;
      this.getInline(value);
    },

    // 查询信息
    getInline(id, isHistory) {
      //防止重复点击
      if (this.$store.state.fishFlag) {
        this.$message({
          showClose: true,
          message: "请勿重复点击，等待搜索结果",
          type: "error",
        });
        return;
      }

      // 查详情
      let pId;
      if (this.$comm.isUrl(id)) {
        pId = this.$comm.UrlParamHash(id)["id"];
      } else {
        pId = id;
      }

      this.particularsSubmit(pId, isHistory);
      this.tableData = [];
      this.option_tjrq = [];

      this.option.xAxis.data = [];
      this.option.series[0].data = [];
    },

    particularsSubmit(id, isHistory) {
      let formObj = {
        itemid: id,
      };

      goodsHistoryPrice(formObj)
        .then((data) => {
          this.tableData = [];
          if (data.data instanceof Array) {
            this.tableData = data.data;
          } else {
            this.tableData = data.data.info;
          }

          let dateArr = [];
          let priceArr = [];
          this.tableData.forEach((item) => {
            if (!item.date) {
              item.date = item.dt || item.date;
            }
            if (!item.price) {
              item.price = item.pr || item.price;
            }
            dateArr.push(item.date);
            priceArr.push(item.price);
          });
          this.option.xAxis.data = dateArr;
          this.option.series[0].data = priceArr;
          this.initCharts();
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false); //关掉遮罩层
          this.CanYouClick = true; //允许再次搜索
        });
    },
  },
};
</script>

<style lang="less" scoped>
.multiple_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.particularsData_box {
  display: flex;
  align-items: center;

  .particularsData_img {
    width: 100px;
    height: 100px;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#ceefff),
      to(#7bd5ff)
    );
    background: linear-gradient(0deg, #ceefff, #7bd5ff);
    margin-right: 50px;
  }
}

.multipleSearch {
  // padding: 80px 200px 40px;
  // text-align: center;
  margin-bottom: 40px;

  /deep/.el-form-item__content {
    width: 100%;
  }
}

.multipleSearchBottom {
  padding: 0 80px 100px;
  text-align: center;
}

.btn {
  display: flex;
  justify-content: flex-end;
  padding: 0 300px;
}

.echarts_box {
  background: #fff;

  .echarts_map {
    height: 430px;
    border: 1px solid #e5e5e5;
    padding: 20px 15px;
    border-radius: 2px;
  }
}

.support-info {
  padding: 10px;
  font-size: 12px;
  text-align: left;
  line-height: 1.8;
  margin-top: 10px;
  .category {
    color: #f56c6c;
  }
}

.detail-body {
  margin-bottom: 30px;
  width: 48%;
}

@media screen and (max-width: 640px) {
  .detail-body {
    width: 100% !important;
  }
}
</style>