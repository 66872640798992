var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('warning-tip',{attrs:{"code":"HISTORICAL_PRICE"}}),_c('main-top',{attrs:{"title":"商品历史价格","desc":"通过商品id可以获取商品历史价格趋势数据，可应用于商品销售情况分析，电商选品分析，品牌口碑挖掘和竞争对手分析等。","spend":"HISTORICAL_PRICE"}},[_c('InputView',{attrs:{"extractProductId":true,"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入您的宝贝链接或宝贝ID"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin":"30px 0"},attrs:{"title":"销售趋势","shadow":""}},[_c('div',{staticClass:"echarts_box"},[_c('div',{ref:"echarts",staticClass:"echarts_map"})])]),_c('bordure-row',{staticStyle:{"margin":"30px 0"},attrs:{"title":"商品信息"}},[_c('el-table',{attrs:{"data":_vm.tableData,"max-height":"500","size":"mini","header-cell-style":{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          'font-size': '14px',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }}},[_c('el-table-column',{attrs:{"label":"日期","prop":"date","align":"center"}}),_c('el-table-column',{attrs:{"label":"价格","prop":"price","align":"center"}})],1)],1)],1),(_vm.$store.state.fishFlag)?_c('SyncLoading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }